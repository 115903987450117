import React from "react";

function HomeSymbol(props: { children: React.ReactNode }) {
  return (
    <div className="w-[5.5rem] h-[5.5rem] max-w-[5.5rem] max-h-[5.5rem] mb-4">
      {props.children}
    </div>
  );
}

export function HomeGithub() {
  return (
    <HomeSymbol>
      <svg viewBox="-0.2 -1 379 334">
        <path
          id="puddle"
          fill="#9CDAF1"
          d="m296.94 295.43c0 20.533-47.56 37.176-106.22 37.176-58.67 0-106.23-16.643-106.23-37.176s47.558-37.18 106.23-37.18c58.66 0 106.22 16.65 106.22 37.18z"
        />
        <path
          id="shadow-legs-left"
          fill="#7DBBE6"
          d="m161.85 331.22v-26.5c0-3.422-.619-6.284-1.653-8.701 6.853 5.322 7.316 18.695 7.316 18.695v17.004c6.166.481 12.534.773 19.053.861l-.172-16.92c-.944-23.13-20.769-25.961-20.769-25.961-7.245-1.645-7.137 1.991-6.409 4.34-7.108-12.122-26.158-10.556-26.158-10.556-6.611 2.357-.475 6.607-.475 6.607 10.387 3.775 11.33 15.105 11.33 15.105v23.622c5.72.98 11.71 1.79 17.94 2.4z"
        />
        <path
          id="shadow-legs-right"
          fill="#7DBBE6"
          d="m245.4 283.48s-19.053-1.566-26.16 10.559c.728-2.35.839-5.989-6.408-4.343 0 0-19.824 2.832-20.768 25.961l-.174 16.946c6.509-.025 12.876-.254 19.054-.671v-17.219s.465-13.373 7.316-18.695c-1.034 2.417-1.653 5.278-1.653 8.701v26.775c6.214-.544 12.211-1.279 17.937-2.188v-24.113s.944-11.33 11.33-15.105c0-.01 6.13-4.26-.48-6.62z"
        />
        <path
          id="cat"
          fill="#000"
          d="m378.18 141.32l.28-1.389c-31.162-6.231-63.141-6.294-82.487-5.49 3.178-11.451 4.134-24.627 4.134-39.32 0-21.073-7.917-37.931-20.77-50.759 2.246-7.25 5.246-23.351-2.996-43.963 0 0-14.541-4.617-47.431 17.396-12.884-3.22-26.596-4.81-40.328-4.81-15.109 0-30.376 1.924-44.615 5.83-33.94-23.154-48.923-18.411-48.923-18.411-9.78 24.457-3.733 42.566-1.896 47.063-11.495 12.406-18.513 28.243-18.513 47.659 0 14.658 1.669 27.808 5.745 39.237-19.511-.71-50.323-.437-80.373 5.572l.276 1.389c30.231-6.046 61.237-6.256 80.629-5.522.898 2.366 1.899 4.661 3.021 6.879-19.177.618-51.922 3.062-83.303 11.915l.387 1.36c31.629-8.918 64.658-11.301 83.649-11.882 11.458 21.358 34.048 35.152 74.236 39.484-5.704 3.833-11.523 10.349-13.881 21.374-7.773 3.718-32.379 12.793-47.142-12.599 0 0-8.264-15.109-24.082-16.292 0 0-15.344-.235-1.059 9.562 0 0 10.267 4.838 17.351 23.019 0 0 9.241 31.01 53.835 21.061v32.032s-.943 11.33-11.33 15.105c0 0-6.137 4.249.475 6.606 0 0 28.792 2.361 28.792-21.238v-34.929s-1.142-13.852 5.663-18.667v57.371s-.47 13.688-7.551 18.881c0 0-4.723 8.494 5.663 6.137 0 0 19.824-2.832 20.769-25.961l.449-58.06h4.765l.453 58.06c.943 23.129 20.768 25.961 20.768 25.961 10.383 2.357 5.663-6.137 5.663-6.137-7.08-5.193-7.551-18.881-7.551-18.881v-56.876c6.801 5.296 5.663 18.171 5.663 18.171v34.929c0 23.6 28.793 21.238 28.793 21.238 6.606-2.357.474-6.606.474-6.606-10.386-3.775-11.33-15.105-11.33-15.105v-45.786c0-17.854-7.518-27.309-14.87-32.3 42.859-4.25 63.426-18.089 72.903-39.591 18.773.516 52.557 2.803 84.873 11.919l.384-1.36c-32.131-9.063-65.692-11.408-84.655-11.96.898-2.172 1.682-4.431 2.378-6.755 19.25-.80 51.38-.79 82.66 5.46z"
        />
        <path
          id="face"
          fill="#F4CBB2"
          d="m258.19 94.132c9.231 8.363 14.631 18.462 14.631 29.343 0 50.804-37.872 52.181-84.585 52.181-46.721 0-84.589-7.035-84.589-52.181 0-10.809 5.324-20.845 14.441-29.174 15.208-13.881 40.946-6.531 70.147-6.531 29.07-.004 54.72-7.429 69.95 6.357z"
        />
        <path
          id="eye-left"
          fill="#FFF"
          d="m160.1 126.06 c0 13.994-7.88 25.336-17.6 25.336-9.72 0-17.6-11.342-17.6-25.336 0-13.992 7.88-25.33 17.6-25.33 9.72.01 17.6 11.34 17.6 25.33z"
        />
        <path
          id="eye-right"
          fill="#FFF"
          d="m254.43 126.06 c0 13.994-7.88 25.336-17.6 25.336-9.72 0-17.6-11.342-17.6-25.336 0-13.992 7.88-25.33 17.6-25.33 9.72.01 17.6 11.34 17.6 25.33z"
        />
        <path
          id="pupil-left"
          fill="#AD5C51"
          d="m154.46 126.38 c0 9.328-5.26 16.887-11.734 16.887s-11.733-7.559-11.733-16.887c0-9.331 5.255-16.894 11.733-16.894 6.47 0 11.73 7.56 11.73 16.89z"
        />
        <path
          id="pupil-right"
          fill="#AD5C51"
          d="m248.88 126.38 c0 9.328-5.26 16.887-11.734 16.887s-11.733-7.559-11.733-16.887c0-9.331 5.255-16.894 11.733-16.894 6.47 0 11.73 7.56 11.73 16.89z"
        />
        <path
          id="nose"
          fill="#AD5C51"
          d="M 188.5 148.56 a 4.401 4.401 0 1 0 0.0001 0"
        />
        <path
          id="mouth"
          fill="#AD5C51"
          d="m178.23 159.69c-.26-.738.128-1.545.861-1.805.737-.26 1.546.128 1.805.861 1.134 3.198 4.167 5.346 7.551 5.346s6.417-2.147 7.551-5.346c.26-.738 1.067-1.121 1.805-.861s1.121 1.067.862 1.805c-1.529 4.324-5.639 7.229-10.218 7.229s-8.68-2.89-10.21-7.22z"
        />
        <path
          id="octo1"
          fill="#C3E4D8"
          d="m80.641 179.82 c0 1.174-1.376 2.122-3.07 2.122-1.693 0-3.07-.948-3.07-2.122 0-1.175 1.377-2.127 3.07-2.127 1.694 0 3.07.95 3.07 2.13z"
        />
        <path
          id="octo2"
          fill="#C3E4D8"
          d="m89.041 184.54 c0 1.174-1.376 2.122-3.07 2.122-1.693 0-3.07-.948-3.07-2.122 0-1.175 1.377-2.127 3.07-2.127 1.694 0 3.07.95 3.07 2.13z"
        />
        <path
          id="octo3"
          fill="#C3E4D8"
          d="m94.234 190.68 c0 1.174-1.376 2.122-3.07 2.122-1.693 0-3.07-.948-3.07-2.122 0-1.175 1.377-2.127 3.07-2.127 1.694 0 3.07.95 3.07 2.13z"
        />
        <path
          id="octo4"
          fill="#C3E4D8"
          d="m98.954 197.76 c0 1.174-1.376 2.122-3.07 2.122-1.693 0-3.07-.948-3.07-2.122 0-1.175 1.377-2.127 3.07-2.127 1.694 0 3.07.95 3.07 2.13z"
        />
        <path
          id="octo5"
          fill="#C3E4D8"
          d="m104.142 204.37 c0 1.174-1.376 2.122-3.07 2.122-1.693 0-3.07-.948-3.07-2.122 0-1.175 1.377-2.127 3.07-2.127 1.694 0 3.07.95 3.07 2.13z"
        />
        <path
          id="octo6"
          fill="#C3E4D8"
          d="m111.232 210.03 c0 1.174-1.376 2.122-3.07 2.122-1.693 0-3.07-.948-3.07-2.122 0-1.175 1.377-2.127 3.07-2.127 1.694 0 3.07.95 3.07 2.13z"
        />
        <path
          id="octo7"
          fill="#C3E4D8"
          d="m121.142 213.81 c0 1.174-1.376 2.122-3.07 2.122-1.693 0-3.07-.948-3.07-2.122 0-1.175 1.377-2.127 3.07-2.127 1.694 0 3.07.95 3.07 2.13z"
        />
        <path
          id="octo8"
          fill="#C3E4D8"
          d="m131.012 213.81 c0 1.174-1.376 2.122-3.07 2.122-1.693 0-3.07-.948-3.07-2.122 0-1.175 1.377-2.127 3.07-2.127 1.694 0 3.07.95 3.07 2.13z"
        />
        <path
          id="octo9"
          fill="#C3E4D8"
          d="m141.022 212.17 c0 1.174-1.376 2.122-3.07 2.122-1.693 0-3.07-.948-3.07-2.122 0-1.175 1.377-2.127 3.07-2.127 1.694 0 3.07.95 3.07 2.13z"
        />
        <path
          id="drop"
          fill="#9CDAF1"
          d="m69.369 186.12l-3.066 10.683s-.8 3.861 2.84 4.546c3.8-.074 3.486-3.627 3.223-4.781z"
        />
      </svg>
    </HomeSymbol>
  );
}

export function HomeMail() {
  return (
    <HomeSymbol>
      <svg viewBox="0 0 348.8 348.8">
        <path
          d="M0.8,42.9c0-6.8,7.9-10.5,13.1-6.2l137.9,114.5c13.3,11.1,32.7,11.1,46,0L335.6,36.8c5.2-4.3,13.1-0.6,13.1,6.2
	v235c0,19.9-16.1,36-36,36h-276c-19.9,0-36-16.1-36-36L0.8,42.9L0.8,42.9z"
          style={{ fill: "#6D4AFF" }}
        ></path>
        <linearGradient
          gradientTransform="matrix(1 0 0 1 0 -28.0537)"
          y2="-108.3067"
          x2="51.985"
          y1="230.3342"
          x1="131.308"
          gradientUnits="userSpaceOnUse"
          id="SVGID_1_"
        >
          <stop style={{ stopColor: "#E2DBFF" }} offset="0"></stop>
          <stop style={{ stopColor: "#6D4AFF" }} offset="1"></stop>
        </linearGradient>
        <path
          d="M221.7,131.4L221.7,131.4l-69.4,61.4c-11.8,10.4-29.5,10.7-41.6,0.6L0.8,101.9v-59c0-6.8,7.9-10.5,13.1-6.2
	l137.9,114.5c13.3,11.1,32.7,11.1,46,0L221.7,131.4L221.7,131.4z"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            fill: "url(#SVGID_1_)",
          }}
        ></path>
        <linearGradient
          gradientTransform="matrix(1 0 0 1 0 -28.0537)"
          y2="-55.4495"
          x2="189.628"
          y1="522.8195"
          x1="460.326"
          gradientUnits="userSpaceOnUse"
          id="SVGID_2_"
        >
          <stop style={{ stopColor: "#E2DBFF" }} offset="0.271"></stop>
          <stop style={{ stopColor: "#6D4AFF" }} offset="1"></stop>
        </linearGradient>
        <path
          d="M274.8,87.3v226.6h38c19.9,0,36-16.1,36-36v-235c0-6.8-7.9-10.5-13.1-6.2L274.8,87.3z"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            fill: "url(#SVGID_2_)",
          }}
        ></path>
      </svg>
    </HomeSymbol>
  );
}

export function HomeDiscord() {
  return (
    <HomeSymbol>
      <svg viewBox="0 -28.5 256 256">
        <g>
          <path
            fillRule="nonzero"
            fill="#5865F2"
            d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
          ></path>
        </g>
      </svg>
    </HomeSymbol>
  );
}
