import React from "react";
import { SessionType } from "../../../models/Session";

export default function SessionDirection(props: { session: SessionType }) {
  return (
    <div
      className="absolute m-auto left-0 right-0 top-0 bottom-0 max-w-[70%] max-h-[70%] aspect-square animate-spin-slow -z-10"
      style={{
        animationDirection: !props.session.globals.direction
          ? "reverse"
          : "normal",
      }}
      key={props.session.globals.direction.toString()}
    >
      <div
        className="h-full w-full"
        style={{
          transform: !props.session.globals.direction ? "scaleX(-1)" : "",
        }}
      >
        <svg viewBox="0 0 1000 1000">
          <g>
            <path
              className="fill-contrast opacity-10"
              d="M678.1,754.1c-54,37.9-117.1,56.8-181.8,55.9c-8.6-0.1-17-0.5-25.4-1.3c-3.4-0.3-6.8-0.9-10.3-1.3c-6.6-0.9-13.2-1.7-19.6-3c-4-0.8-7.9-1.8-11.7-2.7c-6.3-1.4-12.6-2.9-18.7-4.8c-3-0.9-5.9-2-8.8-3c-7.1-2.3-14.1-4.8-21-7.7c-1.5-0.6-3.1-1.4-4.6-2c-8-3.5-15.9-7.2-23.7-11.4c-0.3-0.2-0.7-0.4-1-0.5c-26-14.3-49.8-32.1-70.6-52.9c-0.3-0.3-0.6-0.7-1-1.1c-6.5-6.5-12.6-13.3-18.5-20.4c-1.2-1.5-2.4-3.2-3.7-4.7c-42.4-53.1-68-120.3-68-193.3h81.7l-130.7-196L10,499.9h81.7c0,85.9,26.8,165.6,72.3,231.4c0.5,0.9,0.9,1.9,1.5,2.8c4.7,6.7,10,12.9,15,19.3c1.9,2.4,3.7,4.9,5.6,7.2c7.4,9.1,15.4,17.5,23.5,25.9c0.8,0.8,1.5,1.6,2.3,2.4c27.3,27.3,58.2,50.4,91.8,68.8c0.9,0.5,1.8,1.1,2.7,1.5c9.6,5.2,19.6,9.9,29.7,14.4c2.5,1.1,5,2.3,7.5,3.3c8.6,3.6,17.5,6.7,26.4,9.7c4.2,1.4,8.4,2.9,12.7,4.2c7.8,2.3,15.8,4.2,23.8,6.1c5.4,1.3,10.6,2.6,16,3.6c2.3,0.5,4.4,1.2,6.6,1.5c7.6,1.3,15.2,2.1,22.8,3c2.7,0.3,5.4,0.8,8.2,1.1c13.6,1.3,27.2,2.2,40.7,2.2c83,0,164-25.4,233.5-74c22.2-15.6,27.6-46.1,12-68.2C730.7,743.9,700.2,738.6,678.1,754.1"
            />
            <path
              className="fill-contrast opacity-10"
              d="M908.4,499.9c0-85.7-26.7-165.2-72-230.9c-0.6-1.1-1.1-2.3-1.8-3.3c-5.7-8-11.8-15.5-17.9-23c-0.7-0.9-1.4-1.9-2.1-2.8c-41.4-50.3-93.5-89.1-152.4-114.5c-1.6-0.7-3.2-1.5-4.9-2.2c-9.5-3.9-19.1-7.3-28.8-10.5c-3.5-1.2-6.9-2.4-10.5-3.4c-8.4-2.5-17-4.6-25.7-6.6c-4.8-1.1-9.5-2.3-14.4-3.3c-2.4-0.4-4.6-1.2-7-1.6c-6.5-1.1-12.9-1.6-19.4-2.5c-4.5-0.5-8.8-1.2-13.3-1.7c-10.8-1-21.6-1.4-32.4-1.6c-2,0-3.8-0.3-5.8-0.3c-0.3,0-0.7,0.1-1,0.1c-82.9,0-163.7,25.1-233.1,73.7c-22.2,15.5-27.6,46-12,68.2c15.5,22.2,46.1,27.6,68.2,12c53.6-37.5,116.2-56.5,180.3-55.9c9.2,0,18.3,0.5,27.3,1.4c2.8,0.3,5.5,0.7,8.3,1c7.4,0.9,14.7,2,21.9,3.4c3.2,0.6,6.3,1.4,9.5,2.2c7.1,1.6,14.1,3.3,21,5.4c2.2,0.7,4.3,1.5,6.6,2.2c7.9,2.5,15.7,5.4,23.3,8.5c0.8,0.3,1.6,0.7,2.3,1c45.7,19.6,85.8,49.9,117,87.7c0.2,0.2,0.4,0.5,0.5,0.7c44,53.6,70.5,122,70.5,196.6h-81.7l130.7,196L990,500L908.4,499.9L908.4,499.9z"
            />
          </g>
        </svg>
      </div>
    </div>
  );
}
